
export const columns = [
  {
    title: '大区',
    dataIndex: 'areaName',
    key: 'areaName',
    align: 'center',
  },
  {
    title: '事务所',
    dataIndex: 'firmName',
    key: 'firmName',
    align: 'center',
  },
  {
    title: '经销商名称',
    dataIndex: 'dealerName',
    key: 'dealerName',
    align: 'center',
  },
  {
    title: '对账月份',
    dataIndex: 'checkTime',
    key: 'checkTime',
    align: 'center',
  },
  {
    title: '欠款余额',
    align: 'center',
    className: 'headerYellow',
    children: [
      {
        title: '欠款',
        dataIndex: 'oweAmount',
        key: 'oweAmount',
        align: 'center',
        className: 'headerTextRed',
        scopedSlots: { customRender: 'oweAmount' },
      },
    ],
  },
  {
    title: '可使用余额',
    align: 'center',
    className: 'headerBlue',
    children: [
      {
        title: '可使用余额',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        align: 'center',
        className: 'headerTextDarkGreen',
        scopedSlots: { customRender: 'totalAmount' },
      },
      {
        title: '资金额度',
        dataIndex: 'balanceAmount',
        key: 'balanceAmount',
        align: 'center',
        className: 'headerTextShallowGreen',
        scopedSlots: { customRender: 'balanceAmount' },
      },
      {
        title: '信用额度',
        dataIndex: 'limitAmount',
        key: 'limitAmount',
        align: 'center',
        className: 'headerTextShallowGreen',
        scopedSlots: { customRender: 'limitAmount' },
      },
    ],
  },
  {
    title: '状态',
    dataIndex: 'auditStatus',
    key: 'auditStatus',
    align: 'center',
    scopedSlots: { customRender: 'auditStatus' },
  },
  {
    title: '对账',
    dataIndex: 'confirmStatus',
    key: 'confirmStatus',
    align: 'center',
    scopedSlots: { customRender: 'confirmStatus' },
  },
  {
    title: '对账时间',
    dataIndex: 'statementTime',
    key: 'statementTime',
    align: 'center',
  },
  {
    title: '对账人',
    dataIndex: 'statementUser',
    key: 'statementUser',
    align: 'center',
  },
  {
    title: '查看对账单',
    dataIndex: 'CKDZD',
    key: 'CKDZD',
    align: 'center',
    scopedSlots: { customRender: 'CKDZD' },
  },
  {
    title: '附件',
    dataIndex: 'statementFile',
    key: 'statementFile',
    align: 'center',
    scopedSlots: { customRender: 'statementFile' },
  },
]